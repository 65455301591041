import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import "./css/Cursos.css";
import { Link, useParams } from "react-router-dom";
import Loader from "react-loader-spinner";

import Footer from "../common/Footer";
import SectionTitle from "../common/SectionTitle";
import ApiCache from "../utlis/ApiCache";
import TarjetaCurso from "../common/TarjetaCurso";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import Collapsible from "react-collapsible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faBullhorn,
  faChartBar,
  faFileAlt,
  faFlag,
  faImages,
  faUsers,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import CursosDeOfimatica from "../../images/logo-cursos-de-ofimatica.webp";

import { Helmet } from "react-helmet";

const Cursos = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});

  const [curriculum, setCurriculum] = useState([]);
  const [faq, setFaq] = useState([]);
  const [metaInfo, setMetaInfo] = useState([]);
  const [view, setView] = useState("all");
  const [detalleContent, setDetalleContent] = useState("");
  const [cursosPopulares, setCursosPopulares] = useState([]);

  let { slug } = useParams();

  const endpoint =
    process.env.REACT_APP_API_LMS + `courses?page=1&per_page=1000`;
  //console.log(endpoint);
  const endpointSingle = process.env.REACT_APP_API_LMS + `course?id=${slug}`;

  //console.log(endpointSingle);

  const shuffle = (a) => {
    let j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  };

  let iframeDetalleRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    if (slug === undefined) {
      ApiCache("todos-cursos", endpoint)
        .then((r) => {
          setCourse({});
          setFaq([]);
          setCurriculum([]);
          setMetaInfo([]);
          setCursosPopulares([]);
          setCourses(r.courses);
          setIsLoading(false);
          setView("all");
        })
        .catch((err) => console.log(err));
    } else {
      ApiCache(`curso-${slug}`, endpointSingle)
        .then((r) => {
          setCourses([]);
          setCourse(r);
          setFaq(r.faq);
          setCurriculum(r.curriculum);
          setMetaInfo(r.categories_object);
          setIsLoading(false);
          setView("single");
        })
        .catch((err) => console.log(err));

      ApiCache("todos-cursos", endpoint)
        .then((r) => {
          shuffle(r.courses);
          setCursosPopulares(r.courses);
        })
        .catch((err) => console.log(err));
    }
  }, [slug, endpointSingle, endpoint]);

  const items = courses.map((curso) => {
    return <TarjetaCurso curso={curso} key={`cursos-todos-${curso.id}`} />;
  });

  const getIconCurriculum = (name) => {
    switch (name) {
      case "slide":
        return <FontAwesomeIcon icon={faImages} />;
      case "text":
        return <FontAwesomeIcon icon={faFileAlt} />;
      case "video":
        return <FontAwesomeIcon icon={faVideo} />;
      case "Section 1":
        return <FontAwesomeIcon icon={faFlag} />;
      default:
        return <FontAwesomeIcon icon={faFileAlt} />;
    }
  };

  const itemsCurriculum = curriculum.map((cv, index) => {
    return (
      <div className={"curriculum-row"} key={`curriculum-${index}`}>
        <div className={"curriculum-number"}>{index + 1}</div>
        <div className={"curriculum-icon"}>{getIconCurriculum(cv.view)}</div>
        <div className={"curriculum-label"}>{cv.label}</div>
        <div className={"curriculum-duration"}>
          {cv.duration != null && (
            <>
              <FontAwesomeIcon icon={faClock} /> {cv.duration}
            </>
          )}
        </div>
      </div>
    );
  });

  const itemsDetalle = faq.map((detalle, index) => {
    return (
      <Collapsible
        key={`collapsible-detalle-${index}`}
        trigger={detalle.question}
      >
        {detalle.answer}
      </Collapsible>
    );
  });

  const itemsMeta = metaInfo.map((info, index) => {
    return (
      <Link key={`info-${index}`} to={"#"}>
        {info.name}
      </Link>
    );
  });

  const setDetalleToState = () => {
    setDetalleContent(
      iframeDetalleRef.current.contentWindow.document.body.innerHTML
    );
  };

  const urlWebP = "https://www.cursosoficiales.com/image2.php?url=";

  const mejorarImg = (imgx) => {
    if (imgx === null) {
      return { CursosDeOfimatica };
    } else {
      //console.log(imgx);
      let imgx2 = imgx.split("-");
      //console.log(imgx2);
      const ultimo = imgx2[imgx2.length - 1];
      const vectorExtension = ultimo.split(".");
      imgx2.pop();
      //console.log(imgx2.join("-") + "." + vectorExtension[1]);
      return `${imgx2.join("-")}.${vectorExtension[1]}`;
    }
  };

  const buildCursosPopulares = cursosPopulares.map((item, index) => {
    if (index < 3) {
      return (
        <Link
          to={`/cursos/${item.id}/${encodeURI(item.title)}`}
          className={"invisible-link"}
          key={`popular-${item.id}`}
        >
          <div className={"curso-popular-item"}>
            <div className={"img-squared"}>
              <img src={item.images.small} alt={item.title} />
            </div>
            <h2 className={"title"}>{item.title}</h2>
          </div>
        </Link>
      );
    } else {
      return <i key={"notanusefullelement-" + index} />;
    }
  });

  if (isLoading) {
    return (
      <>
        <Header />
        <SectionTitle title={" "} />
        <div className="container">
          <Loader
            className={"mainLoader"}
            type="Rings"
            color="#00BFFF"
            height={100}
            width={100}
          />
        </div>
        <Footer />
      </>
    );
  }

  if (view === "all" && isLoading === false) {
    return (
      <>
        <Header />
        <Helmet>
          <title>Cursos - Cursos Oficiales</title>
          <meta name="description" content={"Cursos - Cursos Oficiales"} />
          <meta
            name="keyword"
            content="curso de office, curso, word, excel, powerpoint, scratch, certificacion"
          />
        </Helmet>
        <SectionTitle title={"cursos"} />

        <div className={"container all-cursos"}>{items}</div>

        <Footer />
      </>
    );
  }

  if (view === "single" && isLoading === false) {
    //console.log("traigo: " + course?.images?.full);
    return (
      <>
        <Header />
        <SectionTitle title={course.title} />
        <Helmet>
          <title>
            {course.title?.title?.rendered ?? " sin titulo"} - Cursos de
            Ofimática
          </title>
          <meta
            name="description"
            content={course.title?.content?.rendered + "- Cursos Oficiales"}
          />
          <meta
            name="keyword"
            content="curso de office, curso, word, excel, powerpoint, scratch, certificacion"
          />
        </Helmet>
        <div className="container curso-single">
          <main>
            <h2>{course.title}</h2>
            <section className={"meta-categorias"}>
              <div className="icon">
                <FontAwesomeIcon icon={faBookmark} />
              </div>
              <div>
                <h3>Categorias:</h3>
                {itemsMeta}
              </div>
            </section>

            <div className="single-tabs">
              <Tabs style={{ marginTop: 30 }}>
                <TabList>
                  <Tab>Descripción</Tab>
                  <Tab>Curriculum</Tab>
                  <Tab>Detalles</Tab>
                </TabList>

                <TabPanel>
                  <img
                    src={`${urlWebP}${mejorarImg(course?.images?.full)}`}
                    className="img-fluid"
                    alt={""}
                  />

                  {/* Por cosas de la vida, esta fue la mejor forma que encontre de parsear el html de la respuesta */}
                  <div
                    id={"div-detalle"}
                    dangerouslySetInnerHTML={{ __html: detalleContent }}
                  />
                  <iframe
                    srcDoc={course?.description}
                    onLoad={setDetalleToState}
                    title={"i"}
                    ref={iframeDetalleRef}
                  />
                </TabPanel>
                <TabPanel>{itemsCurriculum}</TabPanel>
                <TabPanel>{itemsDetalle}</TabPanel>
              </Tabs>
            </div>
          </main>

          <aside>
            <Link to={"/acceder"} className={"btn-sidebar btn-inscribirse"}>
              Inscribirse
            </Link>
            <a
              href="https://campus.cursosoficiales.com/?from=%2Fadmin_dashboard&log_in_required=true"
              className={"btn-sidebar"}
            >
              Iniciar Sesión
            </a>
            <ul>
              <li key={"list1"}>
                <div>
                  Inscriptios: <b>865 students</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faUsers} />
                </div>
              </li>
              <li key={"list2"}>
                <div>
                  Duración: <b>6</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faClock} />
                </div>
              </li>
              <li key={"list3"}>
                <div>
                  Conferencias: <b>8</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faBullhorn} />
                </div>
              </li>

              <li key={"list4"}>
                <div>
                  Video: <b>2</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faVideo} />
                </div>
              </li>

              <li key={"list5"}>
                <div>
                  Nivel: <b>Intermediario</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faChartBar} />
                </div>
              </li>
            </ul>
            <div className="cursos-populares">
              <h2 className={"title"}>Cursos Populares</h2>
              {buildCursosPopulares}
            </div>
          </aside>
        </div>

        <Footer />
      </>
    );
  }
};

export default Cursos;
