import React, { useEffect, useState } from "react";

import "./css/Form2co.css";
import ApiCache from "../utlis/ApiCache";
import twoCheckout from "../../images/twocheckout.png";
import { NavLink } from "react-router-dom";

const Form2co = (props) => {
  const [card, setCard] = useState({
    sellerId: "251073345172",
    publishableKey: "D7A9BC02-C180-43D7-A7AF-9778B524ED9C",
    ccNo: "",
    expMonth: "",
    expYear: "",
    cvv: "",
  });

  //const endpointIp = "http://ip-api.com/json";
  
  const endpointIp = "https://ipapi.co/json";

  const [persona, setPersona] = useState({
    nombre: "",
    apellido: "",
    email: "",
    contrasenha: "",
    direccion: "",
    ciudad: "",
    departamento: "",
    pais: "",
    ip: "",
    paisCode: "",
    zip: "",
  });

  const [datosJson, setDatosJson] = useState(false);
  const [checkPoliticas, setCheckPoliticas] = useState(false);


  const handleInputCheckPoliticas = () => {
    setCheckPoliticas(!checkPoliticas);
  }

  const Checkbox = ({etiqueta, valor, alCambiar}) => {
    return (
      <div>
        <label>
        <input type="checkbox" checked={valor} onChange={alCambiar} />
          {etiqueta} 
          <NavLink
            activeClassName="active"
            to="/politicas-y-privacidad-de-uso"
          >
            Politicas de Privacidad y Reembolso
          </NavLink>
        </label>
        </div>
    );
  }

  const [token, setToken] = useState("");
  const [returnToken, setReturnToken] = useState(null);

  useEffect(() => {
    window.TCO.loadPubKey("production");
  }, []);

  useEffect(() => {
    //setIsLoading(true);
    ApiCache(`datos-ip`, endpointIp)
      .then((r) => {
        //console.log("los datos IP");
        //console.log(r);
        setPersona({
          ...persona,
          ip: r.ip,
          pais: r.country_name,
          departamento: r.region,
          paisCode: r.country,
          ciudad: r.city,
          /*ip: r.query,
          pais: r.country,
          departamento: r.regionName,
          paisCode: r.countryCode,
          ciudad: r.city,*/
        });

        //setPosts(r);
        //setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [endpointIp]);

  const submitted = (e) => {
    e.preventDefault();
    var payWithCard = (data) => {
      console.log(data.response.token.token);
      setToken(data.response.token.token);
      enviar(persona, card, data.response.token.token);
    };

    var error = (error) => {
      console.log(error);
    };

    try {
      window.TCO.requestToken(payWithCard, error, card);
    } catch (error) {
      setTimeout(() => {
        window.TCO.requestToken(payWithCard, error, card);
      }, 3000);
    }
  };

  const change = (e) => {
    setCard({
      ...card,
      [e.target.name]: e.target.value,
    });
    //console.log('card:');
    //console.log("card:");
    //
    //console.log(e.target.name);
  };

  const changePersona = (e) => {
    setPersona({
      ...persona,
      [e.target.name]: e.target.value,
    });
    //console.log('card:');
    //
    //
    //console.log("persona:");
    //console.log(process.env);
    //console.log(process.env.REACT_APP_API_LOCAL);
    //
    //console.log(e.target.name);
  };

  const enviar = async (estudiante, tarjeta, token) => {
    const payload = {
      card_nro: tarjeta.ccNo,
      exp_month: tarjeta.expMonth,
      exp_year: tarjeta.expYear,
      cvc: tarjeta.cvv,
      token: token,
      nombre: estudiante.nombre,
      apellido: estudiante.apellido,
      email: estudiante.email,
      contrasenha: estudiante.contrasenha,
      direccion: estudiante.direccion,
      departamento: estudiante.departamento,
      ciudad: estudiante.ciudad,
      pais: estudiante.pais,
      paisCode: estudiante.paisCode,
      producto: "Y7F9SFK44P",
      ip: estudiante.ip,
      zip: estudiante.zip,
    };

    console.log(payload);
    let formBody = [];
    for (let property in payload) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(payload[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const data = await fetch(
      process.env.REACT_APP_API_PRD + "public/api/crear_orden",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      }
    );

    const json_data = await data.json();
    console.log(data);
    //window.alert(json_data.mesasge);
    console.log(json_data);
    setDatosJson(json_data);
  };

  return (
    <form
      id="tcoCCForm"
      onSubmit={submitted}
      className={"contact-form acceder-holder"}
    >
      <div className="datos-personales">
        <div className="sidebar-decorator" />
        <h1>Datos Personales</h1>
        <input id="sellerId" type="hidden" value={card.sellerId} />
        <input id="ip" type="hidden" value={persona.ip} />
        <input id="ciudad" type="hidden" value={persona.ciudad} />
        <input id="departamento" type="hidden" value={persona.departamento} />
        <input id="pais" type="hidden" value={persona.pais} />
        <input id="paisCode" type="hidden" value={persona.paisCode} />
        <input id="publishableKey" type="hidden" value={card.publishableKey} />
        <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="nombre">
            Nombre
          </label>
          <input
            name="nombre"
            type="text"
            id="nombre"
            value={persona.nombre}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div>
        <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="apellido">
            Apellido
          </label>
          <input
            name="apellido"
            type="text"
            id="apellido"
            value={persona.apellido}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div>
        <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="email">
            Correo Electronico
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={persona.email}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div>
        <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="contrasenha">
            Contraseña
          </label>
          <input
            name="contrasenha"
            type="password"
            id="contrasenha"
            value={persona.contrasenha}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div>
        <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="direccion">
            Direccion
          </label>
          <input
            name="direccion"
            type="text"
            id="direccion"
            value={persona.direccion}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div>
        <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="zip">
            Postal - Zip
          </label>
          <input
            name="zip"
            type="text"
            id="zip"
            value={persona.zip}
            placeholder={"En caso de no poseer, rellenar con 0000"}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div>
        {/* <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="Ciudad">
            Ciudad
          </label>
          <input
            name="ciudad"
            type="text"
            id="ciudad"
            value={persona.ciudad}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div>
        <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="departamento">
            Departamento | Estado
          </label>
          <input
            name="departamento"
            type="text"
            id="departamento"
            value={persona.departamento}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div> */}
        {/* <div className={"input-group"}>
          <label className="label-inscripcion" htmlFor="pais">
            Pais
          </label>
          <input
            name="pais"
            type="text"
            id="pais"
            value={persona.pais}
            autoComplete="off"
            required
            onChange={(e) => changePersona(e)}
          />
        </div> */}
      </div>
      <div className="pedido">
        <div className="sidebar-decorator" />
        <h1 className="pedido-title">Tu pedido</h1>
        <div>
          <table
            className={"shop_table woocommerce-checkout-review-order-table"}
          >
            <thead>
              <tr>
                <th className={"product-name"}>Producto</th>
                <th className={"product-total"}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr className={"cart_item"}>
                <td className={"product-name"}>
                  Suscripción Anual&nbsp;
                  <span className={"product-quantity"}>× 1</span>
                </td>
                <td className={"product-total"}>
                  <span className={"woocommerce-Price-amount amount"}>
                    <bdi>
                      <span className={"woocommerce-Price-currencySymbol"}>
                        $
                      </span>
                      59.00
                    </bdi>
                  </span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className={"cart-subtotal"}>
                <th>Subtotal</th>
                <td>
                  <strong>
                    <span className={"woocommerce-Price-amount amount"}>
                      <bdi>
                        <span className={"woocommerce-Price-currencySymbol"}>
                          $
                        </span>
                        59.00
                      </bdi>
                    </span>
                  </strong>
                </td>
              </tr>

              <tr className={"order-total"}>
                <th>Total</th>
                <td>
                  <strong>
                    <span className={"woocommerce-Price-amount amount"}>
                      <bdi>
                        <span className={"woocommerce-Price-currencySymbol"}>
                          $
                        </span>
                        59.00
                      </bdi>
                    </span>
                  </strong>{" "}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <h3 className="pago-title">Pago</h3>
        <div className="card">
          <div className={"input-pago"}>
            <label>
              {/* <span className="pago-option">Número de tarjeta </span>*/}
              <input
                id="ccNo"
                name="ccNo"
                type="text"
                value={card.ccNo}
                autoComplete="off"
                required
                onChange={(e) => change(e)}
                placeholder="Número de tarjeta"
              />
            </label>
          </div>
          <div className={"input-pago"}>
            <label>
              {/* <span className="pago-option">CVC</span> */}
              <input
                id="cvv"
                name="cvv"
                type="text"
                value={card.cvv}
                autoComplete="off"
                required
                onChange={(e) => change(e)}
                placeholder="CVC"
              />
            </label>
            <span className="mensaje-cvs">
              Los 3 o 4 numeros al reverso de la tarjeta
            </span>
          </div>
          <div className={"input-pago__fecha"}>
            <label>
              <span className="pago-option">Fecha de expiración (MM/AAAA)</span>
              <br />
              <div>
                <select
                  value={card.expMonth}
                  onChange={(e) => change(e)}
                  name="expMonth"
                >
                  <option value="01">ENE</option>
                  <option value="02">FEB</option>
                  <option value="03">MAR</option>
                  <option value="04">ABR</option>
                  <option value="05">MAY</option>
                  <option value="06">JUN</option>
                  <option value="07">JUL</option>
                  <option value="08">AGO</option>
                  <option value="09">SEP</option>
                  <option value="10">OCT</option>
                  <option value="11">NOV</option>
                  <option value="12">DIC</option>
                </select>
                <span> / </span>
                <select
                  value={card.expYear}
                  onChange={(e) => change(e)}
                  name="expYear"
                >
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                </select>
              </div>
            </label>
            {/* <span>Expiration Date (MM/YYYY)</span>
              <input
                type="text"
                size="2"
                id="expMonth"
                name="expMonth"
                value={card.expMonth}
                required
                onChange={(e) => change(e)}
              /> 
            </label>
            <span> / </span>
            <input
              type="text"
              size="4"
              id="expYear"
              name="expYear"
              value={card.expYear}
              required
              onChange={(e) => change(e)}
            /> */}
            <div className="submit-section">
              <img src={twoCheckout} alt="TwoCheckout Logo" />
              <input type="submit" value="Comprar" disabled={checkPoliticas ? ''  : 'disabled'} />
            </div>
          </div>
        </div>
        <Checkbox 
          etiqueta={' He leído y acepto las condiciones establecidas en '}
          valor={checkPoliticas}
          alCambiar={handleInputCheckPoliticas}
        />
      </div>

      <div
        className={datosJson !== false ? `test ${datosJson.status}` : "test "}
      >
        {datosJson !== false && datosJson.msg}
      </div>
    </form>
  );
};

export default Form2co;
