import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import "./css/Preguntas.css";
import SectionTitle from "../common/SectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Helmet } from "react-helmet";

const Preguntas = (props) => {
  const [visible, setVisible] = useState("");
  const [consulta, setConsulta] = useState(0);

  const mostrar = (id) => {
    setVisible("mostrar");
    setConsulta(id);
  };

  return (
    <>
      <Header />
      <Helmet>
        <title>Preguntas - Cursos Oficiales</title>
        <meta name="description" content={"Preguntas - Cursos Oficiales"} />
        <meta
          name="keyword"
          content="curso de office, curso, word, excel, powerpoint, scratch, certificacion"
        />
      </Helmet>
      <SectionTitle title="Preguntas" />
      <main>
        <div className="questions-section">
          <h2 className="section-title-questions">Preguntas frecuentes</h2>
          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => mostrar(1)}
            >
              <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className="icon-preguntas"
              />
            </div>
            <div
              className={
                visible === "mostrar" && consulta === 1
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                vero dicta maxime itaque unde cumque doloremque. Tenetur dolore
                consectetur eaque omnis quam, quod sit iste. Libero repellat
                commodi perferendis voluptatem!
              </p>
            </div>
          </button>
          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => mostrar(2)}
            >
              <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className="icon-preguntas"
              />
            </div>
            <div
              className={
                visible === "mostrar" && consulta === 2
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                vero dicta maxime itaque unde cumque doloremque. Tenetur dolore
                consectetur eaque omnis quam, quod sit iste. Libero repellat
                commodi perferendis voluptatem!
              </p>
            </div>
          </button>
          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => mostrar(3)}
            >
              <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className="icon-preguntas"
              />
            </div>
            <div
              className={
                visible === "mostrar" && consulta === 3
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                vero dicta maxime itaque unde cumque doloremque. Tenetur dolore
                consectetur eaque omnis quam, quod sit iste. Libero repellat
                commodi perferendis voluptatem!
              </p>
            </div>
          </button>
          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => mostrar(4)}
            >
              <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className="icon-preguntas"
              />
            </div>
            <div
              className={
                visible === "mostrar" && consulta === 4
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                vero dicta maxime itaque unde cumque doloremque. Tenetur dolore
                consectetur eaque omnis quam, quod sit iste. Libero repellat
                commodi perferendis voluptatem!
              </p>
            </div>
          </button>
          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => mostrar(5)}
            >
              <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className="icon-preguntas"
              />
            </div>
            <div
              className={
                visible === "mostrar" && consulta === 5
                  ? `responses ${visible}`
                  : `responses`
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                vero dicta maxime itaque unde cumque doloremque. Tenetur dolore
                consectetur eaque omnis quam, quod sit iste. Libero repellat
                commodi perferendis voluptatem!
              </p>
            </div>
          </button>
          <button className="questions-button">
            <div
              className="question"
              id="question1"
              type="button"
              onClick={() => mostrar(6)}
            >
              <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h3>
              <FontAwesomeIcon
                icon={faGreaterThan}
                className="icon-preguntas"
              />
            </div>
            <div
              className={
                visible === "mostrar" && consulta === 6
                  ? `responses ${visible}`
                  : `responses ocultar`
              }
            >
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                vero dicta maxime itaque unde cumque doloremque. Tenetur dolore
                consectetur eaque omnis quam, quod sit iste. Libero repellat
                commodi perferendis voluptatem!
              </p>
            </div>
          </button>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Preguntas;
