import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import CarouselCursos from "../common/CaruselCursos";
import { Recuadro } from "../common/Recuadro";

import "react-tabs/style/react-tabs.css";
import "./css/Nosotros.css";
import "./css/LandingExcel.css";

import persona from "../../images/P1.webp";
import Avanzado from "../../images/Curso-Excel-Avanzado.png";
import inicial from "../../images/Curso-Excel-Inicial.png";
import Intermedio from "../../images/Curso-Excel-Inter.png";
import Beneficios from "../../images/02.png";
import excelLogo from "../../images/landing-Word-Objeto1.webp";
import wordLogo from "../../images/landing-Word-Objeto2.webp";
import powerPointLogo from "../../images/landing-Word-Objeto3.webp";
import windowsLogo from "../../images/landing-Word-Objeto4.webp";
import scratchLogo from "../../images/landing-Word-Objeto5.webp";
import personaje from "../../images/personaje-Banner-02.png";
import personajeBanner from "../../images/personaje-Banner-03.png";
import personajeBanner2 from "../../images/personaje-Banner-04.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

const LandingExcel = () => {
  return (
    <>
      <Header />
      <main>
        <section className="primera-seccion-landing-excel">
          <img src={persona} alt="fondo" loading="lazy" />
          <div className="primera-seccion-landing-excel__texto">
            <span>¡Aprende!</span>
            <span className="primera-seccion-landing-excel__texto--microsoft">
              Microsoft
            </span>
            <p>¡Desde cero hasta lograr un nivel profesional!</p>
            <p>
              De la mano de una de las mejores instituciones tecnológicas
              educativas de Latinoamérica, con profesionales de amplia
              trayectoria pedagógica y Certificación en Microsoft Office
              Specialist.
            </p>
          </div>
        </section>

        <section className="segunda-seccion-landing-excel">
          <Recuadro
            source={inicial}
            imagenTexto={"Excel Inicial"}
            texto={"Curso de Excel Inicial"}
            direccion={"/"}
          />
          <Recuadro
            source={Intermedio}
            imagenTexto={"Excel Intermedio"}
            texto={"Curso de Excel Intermedio"}
            direccion={"/"}
          />
          <Recuadro
            source={Avanzado}
            imagenTexto={"Excel Avanzado"}
            texto={"Curso de Excel Avanzado"}
            direccion={"/"}
          />
        </section>

        <section className="tercera-seccion-landing-excel">
          <a href="/" alt="beneficios">
            ¡Aprende con todos estos súper beneficios!
          </a>
          <div className="beneficios-contenedor">
            <div className="beneficios-primera-parte">
              <ul>
                <li>
                  Cursos de Excel con vídeo tutoriales didácticos, prácticos y a
                  propio ritmo.
                </li>
                <li>
                  Los cursos son desarrollados por profesores certificados en
                  Microsoft Office Specialist y con didáctica educativa.
                </li>
                <li>
                  Los Cursos de Excel están divididos por niveles, Excel
                  Inicial, Excel Intermedio y Excel Avanzado.
                </li>
                <li>
                  Estos cursos están orientados a estudiantes de colegios y
                  universidades, empleados y emprendedores.
                </li>
              </ul>
            </div>
            <div className="beneficios-segunda-parte">
              <img src={Beneficios} alt="Imagen de Beneficios" loading="lazy" />
            </div>
            <div className="beneficios-tercera-parte">
              <ul>
                <li>
                  Los cursos pueden ser desarrollados desde una notebook, tablet
                  o a través de dispositivos móviles.
                </li>
                <li>
                  Asistencia y soporte de profesores en línea para consultas.
                </li>
                <li>Se otorgan certificados luego de culminar cada nivel.</li>
                <li>
                  Con una sola cuenta, pueden capacitarse toda la familia.
                </li>
              </ul>
              <a href="./acceder">
                <button type="button" title="Suscribirse">
                  ¡Inscribirse ahora!
                </button>
              </a>
            </div>
          </div>
        </section>

        <section className="cuarta-seccion-landing-excel">
          <div className="cuarta-seccion__logos-cursos">
            <img src={excelLogo} alt="Logo de Excel" />
            <img src={wordLogo} alt="Logo de Word" />
            <img src={powerPointLogo} alt="Logo de PowerPoint" />
            <img src={windowsLogo} alt="Logo de Windows" />
            <img src={scratchLogo} alt="Logo de Scratch" />
          </div>
          <div className="cuarta-seccion__foto">
            <img src={personaje} alt="Foto ilustrativa de hombre" />
          </div>
          <div className="cuarta-seccion__texto">
            <span>
              <b>Suscribiendote a los cursos de Excel</b>
            </span>
            <span>
              Te obsequamos los cursos de Microsoft Windows y de Microsoft Word
            </span>
            <span>
              Además de todos los cursos, automáticamente te obsequamos otros
              cursos especialmente diseñados para niños, niñas y adolescentes:{" "}
              <b>Windows, Word, PowerPoint y Scratch,</b> para que también los
              más pequeños aprendan al mismo tiempo y sin costo adicional, desde
              la seguridad del hogar y como apoyo pedagogógico para el mejor
              desarrollo de sus tareas escolares.
            </span>
            <button type="button">
              <a href="/">¡Inscribirse ahora!</a>
            </button>
          </div>
        </section>

        <section className="quinta-seccion-landing-excel">
          <CarouselCursos title="Todos estos cursos, y para toda la familia" />
        </section>

        <section className="sexta-seccion-landing-excel">
          <img src={personajeBanner} alt="Persona usando notebook" />
          <div className="sexta-seccion-landing-excel__texto">
            <div className="sexta-seccion-landing-excel__titulos">
              <span>
                Todos estos cursos, a un precio promocional por{" "}
                <span className="texto-verde">tiempo limitado</span>
              </span>
              <span>Precio normal: Suscripción anual de USD 120</span>
              <span>
                Precio promocional: Suscripción anual de <b>USD 59</b>
              </span>
            </div>
            <div className="sexta-seccion-landing-excel__beneficios">
              <div className="sexta-seccion-landing-excel__beneficios--primera-seccion">
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="icono-excel-landing"
                  />
                  Suscripción anual de todos nuestros cursos oficiales,
                  incluyendo Windows, Word, Excel, PowerPoint, Scratch.
                </span>
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="icono-excel-landing"
                  />
                  Cursos dirigidos a emprendedores, empleados, universitarios,
                  niños/as y adolescentes.
                </span>
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="icono-excel-landing"
                  />
                  Acceso a todos los cursos nuevos que se agregan o actualizan
                  periódicamente.
                </span>
              </div>
              <div className="sexta-seccion-landing-excel__beneficios--segunda-seccion">
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="icono-excel-landing"
                  />
                  Acceso a todos los recursos del Campus Virtual (videos,
                  presentaciones, documentos, planillas, ejercitarios, etc.).
                </span>
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="icono-excel-landing"
                  />
                  Docentes calificados que brindan asistencia en informar
                  permanentemente.
                </span>
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="icono-excel-landing"
                  />
                  Certificado digital al término de cada módulo.
                </span>
                <span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="icono-excel-landing"
                  />
                  Con garantía de reembolso durante 60 días.
                </span>
              </div>
              <button>
                <a href="/">¡Inscribirse ahora!</a>
              </button>
            </div>
          </div>
        </section>

        <section className="septima-seccion-landing-excel">
          <div>
            <span>¿Deseas conocer un poco más de nosotros?</span>
            <span>
              A través de nuestro apartado de Preguntas Frecuentes conocerás un
              poco más de las características de nuestros servicios educativos,
              y de nuestro Grupo Corporativo.
            </span>
            <div className="septima-seccion-landing-excel__seccion-boton">
              <a href="/">
                <button type="button">¡Inscribirse ahora!</button>
              </a>
            </div>
          </div>
          <img src={personajeBanner2} alt="Foto ilustrativa de mujer" />
        </section>
      </main>
      <Footer />
    </>
  );
};

export default LandingExcel;
