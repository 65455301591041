import React, { useEffect, useState } from "react";
import "./Footer.css";
import ApiCache from "../utlis/ApiCache";
import { NavLink } from "react-router-dom";

import CursosDeOfimatica from "../../images/logo-cursos-de-ofimatica.webp";

const Footer = (props) => {
  const endpoint = process.env.REACT_APP_API_WP + `posts?per_page=2&_embed`;
  const [footerPost, setFooterPost] = useState([]);

  useEffect(() => {
    ApiCache("footer-blogs", endpoint).then((r) => {
      const posts = r.map((post) => {
        return {
          id: post.id,
          img:
            post["_embedded"]["wp:featuredmedia"][0].media_details.sizes
              .thumbnail.source_url ?? "",
          title: post.title.rendered,
        };
      });
      setFooterPost(posts);
    });
  }, [endpoint]);

  const urlWebP = "https://www.cursosoficiales.com/image2.php?url=";

  const mejorarImg = (imgx) => {
    if (imgx === null) {
      return { CursosDeOfimatica };
    } else {
      //console.log(imgx);
      let imgx2 = imgx.split("-");
      //console.log(imgx2);
      const ultimo = imgx2[imgx2.length - 1];
      const vectorExtension = ultimo.split(".");
      imgx2.pop();
      //console.log(imgx2.join("-") + "." + vectorExtension[1]);
      return `${imgx2.join("-")}.${vectorExtension[1]}`;
    }
  };

  const mejorarTitulo = (titulo) => {
    const mejorar = titulo.toLowerCase();
    const mejorar1 = mejorar
      .replace(/ /g, "-")
      .replace("?", "")
      .replace("¿", "")
      .replace("!", "")
      .replace("¡", "");
    const mejorar2 = mejorar1
      .replace(/ñ/g, "n")
      .replace(/á/g, "a")
      .replace(/é/g, "e")
      .replace(/í/g, "i")
      .replace(/ó/g, "o")
      .replace(/ú/g, "u")
      .replace(/ü/g, "u");
    return mejorar2;
  };

  const blog_footer = footerPost.map((blog) => {
    //console.log(blog)
    return (
      <div key={blog.id} className="entry">
        <div className="img">
          <img src={`${urlWebP}${mejorarImg(blog.img)}`} alt="" />
        </div>
        <div className="title">
          <NavLink
            activeClassName="active"
            to={`/blog/${mejorarTitulo(blog.title)}`}
          >
            <h4>{blog.title}</h4>
          </NavLink>
        </div>
      </div>
    );
  });

  return (
    <>
      <footer>
        <div className="container">
          <div className="acerca">
            <h3>Acerca de</h3>
            <p>
              Estamos comprometidos con cada uno
              <br />
              de nuestros alumnos para que logren
              <br />
              obtener las competencias de calidad
              <br />
              requeridas, y que a través de las
              <br />
              mismas accedan al éxito profesional.
            </p>
          </div>
          <div className="contacto">
            <h3>Contacto</h3>
            <p>Asunción, Paraguay</p>
            <div className="numeros">
              <p>Tel: +1 212 458 300 32</p>
              <p>Fax: +1 212 375 24 14</p>
            </div>
            <a href="mailto:info@cursosoficiales.com">
              info@cursosoficiales.com
            </a>
          </div>
          <div className="paginas">
            <h3>Páginas</h3>
            <ul>
              <li>
                <NavLink activeClassName="active" to="/cursos">
                  Cursos
                </NavLink>
              </li>

              <li>
                <NavLink activeClassName="active" to="/blog">
                  Blog
                </NavLink>
              </li>

              {/* <li>
                <NavLink activeClassName="active" to="/preguntas">
                  Preguntas
                </NavLink>
              </li> */}
              <li>
                <NavLink activeClassName="active" to="/sobre-nosotros">
                  Nosotros
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/contacto">
                  Contacto
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="active"
                  to="/politicas-y-privacidad-de-uso"
                >
                  Politicas de Privacidad y Reembolso
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="blog">
            <h3>Blog</h3>
            <div className="entries">{blog_footer}</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
