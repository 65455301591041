import React from "react";

import Header from "../common/Header";
import "./css/Nosotros.css";

import SectionTitle from "../common/SectionTitle";

import Formd from "./Formulario";
import Form2co from "./Form2co";

import Footer from "../common/Footer";

import persona from "../../images/personaje -Banner-Inscripción.png";
import background from "../../images/Banner-Inscripción.jpg";
import excelLogo from "../../images/landing-Word-Objeto1.webp";
import manInfo from "../../images/personaje--Banner2-Inscripción.png";
import sello from "../../images/sello-garantia.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { Helmet } from "react-helmet";

import "./css/Acceder.css";

const Acceder = (props) => {
  return (
    <>
      <Header />
      <Helmet>
        <title>Inscribirse - Cursos Oficiales</title>
        <meta
          name="description"
          content={"Inscribirse - Cursos Oficiales"}
        />
        <meta
          name="keyword"
          content="curso de office, curso, word, excel, powerpoint, scratch, certificacion"
        />
      </Helmet>
      <SectionTitle title={"Confirmar Inscripción"} />
      <section
        className={"section-title-tipo"}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="info-container">
          <img src={persona} alt="" className="img-fluid tipo" />
          <div className="text">
            <span className="pago-anual">
              Pago de Inscripción Anual promocional de
            </span>
            <span className="precio">
              <span className="usd">USD 59</span> (DÓLARES AMERICANOS CINCUENTA
              Y NUEVE).
            </span>
            <p>
              Nuestros cursos virtuales están desarrollados por docentes que
              fueron certificados como Microsoft Office Specialist (MOS), poseen
              una amplia trayectoria como educadores, y nuestros servicios están
              avalados por el Grupo IDT (una institución educativa con 22 años
              de trayectoria profesional).
            </p>
            <p>
              Al acceder a la membresía, tendrá acceso inmediato a todos los
              cursos desarrollados en la plataforma. No se necesita instalar
              ningún software adicional para comenzar con el curso 100% virtual.
              Los materiales podrás verlo en línea utilizando un navegador web.
              Para cualquier consulta se habilita el siguiente correo: <br />
              <a href="malito:info@cursosoficiales.com">
                info@cursosoficiales.com
              </a>
            </p>
          </div>
        </div>
      </section>
      {/*     <h3 style={ {textAlign:'center', margin:100}}>En construcción, loco</h3> */}
      {/* <Formd /> */}
      <section className={"container-form"}>
        <Form2co />
      </section>
      <section className="man-info">
        <div className="man-info__text">
          <span className="man-info__text--title">
            ¡Solo por este mes
            <br />
            disfrute de todos los cursos
            <br />
            por USD 50!
          </span>
          <span className="cursos-online">
            ¡Accede a los mejores Cursos online!
          </span>
        </div>
        <div className="man-section">
          <img className="man-image" src={manInfo} alt="Foto de niña" />
        </div>
      </section>
      <section className="info-pay-section">
        <div className="pay-includes">
          <h3>Con el pago de la Inscripción Anual se incluye:</h3>
          <div className="check-section">
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>El curso de de Microsoft Excel Básico para adultos</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>El curso de de Microsoft Excel Intermedio para adultos</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>El curso de de Microsoft Excel Avanzado para adultos</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>El curso de de Microsoft Word Básico para adultos</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>El curso de de Microsoft Word Intermedio para adultos</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>El micro curso de Cómo crear una carátula e índice con Word</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>El micro curso de Cómo crear un presupuesto en Excel</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>
                El micro curso de Cómo crear plantillas condicionadas con Excel
              </p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>
                El micro curso de Cómo crear y administrar un sistema de
                inventario con Excel
              </p>
            </div>
          </div>
        </div>
        <div className="pay-actualizations">
          <h3>
            Además de esto podrás obtener con la misma Inscripción Anual el
            acceso a actualizaciones de:
          </h3>
          <div className="check-section">
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>Cualquier curso o micro curso nuevo que se vaya agregando</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>Cualquier actualización de los cursos actuales</p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>
                Todas las plantillas y/o recursos que compartamos con nuestros
                estudiantes
              </p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>
                Recursadas de todos o cualquier curso o micro curso que deseen
                realizar
              </p>
            </div>
            <div className="check-text">
              <FontAwesomeIcon icon={faCheck} />
              <p>
                Certificados por cada curso aprobado, para el titular de la
                cuenta
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="guarantee-section">
        <div className="conditions">
          <h2>Condiciones de la venta:</h2>
          <div className="check-text">
            <FontAwesomeIcon icon={faCircle} />
            <p>
              Su compra aparecerá en su estado de cuenta bajo el nombre
              "CLKBANK*COM".
            </p>
          </div>
          <div className="check-text">
            <FontAwesomeIcon icon={faCircle} />
            <p>
              ClickBank permitirá que se devuelva o reemplace todo producto
              adquirido dentro de los 60 días a partir de la fecha de la compra.
              Para obtener más información al respecto, vea nuestra política de
              devoluciones.
            </p>
          </div>
          <div className="check-text">
            <FontAwesomeIcon icon={faCircle} />
            <p>
              Se ha determinado y registrado la ubicación geográfica de su
              computadora. Todo intento de fraude tendrá repercusiones legales.
            </p>
          </div>
          <div className="check-text">
            <FontAwesomeIcon icon={faCircle} />
            <p>
              Toda información presentada con este pedido se maneja con las
              limitaciones de nuestra política de privacidad.
            </p>
          </div>
          <div className="check-text">
            <FontAwesomeIcon icon={faCircle} />
            <p>
              Al ingresar su correo electrónico en la parte superior, usted
              autoriza a ClickBank a compartir esa información con el vendedor y
              el afiliado relacionados a éste producto para fines de envíos y
              fines de mercadotecnia.
            </p>
          </div>
          <div className="check-text">
            <FontAwesomeIcon icon={faCircle} />
            <p>
              El Mercado de ClickBank no es un banco autorizado, una empresa de
              fideicomiso ni una institución de depósito.
            </p>
          </div>
          <div className="check-text">
            <FontAwesomeIcon icon={faCircle} />
            <p>
              El Mercado de ClickBank no está autorizado a aceptar depósitos ni
              cuentas de fideicomiso y no cuenta con licencia de ninguna
              autoridad bancaria federal o estatal ni está regulado por estas.
            </p>
          </div>
        </div>
        <div className="guarantee">
          <h2>¡Este producto está garantizado!</h2>
          <p>
            ClickBank es el minorista de productos en este sitio.
            <br />
            CLICKBANK® es una marca registrada de Click Sales, Inc.,
            <br />
            una corporación de Delawere ubicada en 1444 S.
            <br />
            Entertainment Ave., Suite 410 Boise, ID 83709, EE. UU. Y
            <br />
            utilizada con permiso. ClickBank's su papel como minorista
            <br />
            no constituye un respaldo, aprobación o revisión de estos
            <br />
            productos o cualquier afirmación, declaración u opinión
            <br />
            utilizada en la promoción de estos productos
          </p>
          <img src={sello} alt="Sello de garantía" />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Acceder;
