import React from "react";

import "./Banner.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import chicaNotebook from "../../images/Personaje-Banner-Principal-Home.png";

const Banner = () => {
  return (
    <>
      <div className="carousel-item">
        <div className="banner-principal-container">
          <div className="carousel-tag">
            La educación del
            <br />
            <span className="futuro">futuro</span>
            <span className="profesionales">
              Aprende con los mejores profesionales
            </span>
          </div>
          <img src={chicaNotebook} alt="Imagen de chica" />
        </div>
      </div>
    </>
  );
};

export default Banner;
